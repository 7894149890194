@use '../abstracts/colors';

@mixin absCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin infoCard {
	background-color: colors.$color-white;
	z-index: 2;
	padding: 2rem;
	overflow: hidden;
	box-shadow: 0 1.5rem 4rem rgba(colors.$color-black, 0.15);
	@apply rounded;
}
