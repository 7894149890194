@use '../abstracts/colors';
@use '../abstracts/mixins';

.popup {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(colors.$color-black, 0.6);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&Show {
		visibility: visible;
		opacity: 1;
	}

	&Content {
		@include mixins.absCenter;
		width: 80%;
		height: 60rem;
		margin: 0 auto;
		background-color: white;
		box-shadow: 0 2rem 4rem rgba(colors.$color-black, 0.2);
		opacity: 0;
		transform: translate(-50%, -50%) translateY(-500px);
		transition: all 0.3s;
		&Show {
			opacity: 1;
			transform: translate(-50%, -50%) translateY(0);
		}

		& ion-icon {
			right:2rem;
			font-size: 2.4rem;
			top:2rem;
			position: absolute;
			cursor: pointer;
		}
	}
}
