@tailwind base;
@tailwind components;
@tailwind utilities;

/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

- Line heights
Default: 1
Small: 1.05
Medium: 1.2
Paragraph default: 1.6
Large: 1.8

- Letter spacing
-0.5px
0.75px

--- 02 COLORS

- Primary:
- Tints:

- Shades: 
#cf711f
#45260a

- Accents:
- Greys

#888
#767676 (lightest grey allowed on #fff)
#6f6f6f (lightest grey allowed on #fdf2e9)
#555
#333

--- 05 SHADOWS

0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);

--- 06 BORDER-RADIUS

Default: 9px
Medium: 11px

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}
/* this defines what 1 rem is - that's why it's not in the typography.scss */
html,
body {
	min-height: 100vh;
}
html {
	font-size: 62.5%;
	font-family: 'RALEWAY', serif;
	min-height: 100vh;
}

body {
	box-sizing: border-box;
	color: #555b6e;
	display: flex;
	flex-direction: column;
}

ion-icon {
	font-size: 1.6rem;
}


#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}