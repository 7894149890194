@use '../abstracts/colors';
@use '../abstracts/mixins';
@use '../base/animations';

.mobileMenuBtnContainer {
	justify-content: flex-end;
}

.mobileMenu {
	@include mixins.absCenter;
	display: flex;
	justify-content: center;
	padding-top: 18rem;
	z-index: 4;
	width: 100%;
	height: 100vh;
	opacity: 0;
	position: fixed;
	background: colors.$color-white;
	transform: translateX(-500px);
	transition: all 0.6s ease-in-out;
	-webkit-transform: translateX(-500px);
	-webkit-transition: all 0.6s ease-in-out;
	&.open {
		touch-action: none;
		opacity: 0.98;
		transform: translate(-50%, -50%) translateX(0);
		-webkit-transform: translate(-50%, -50%) translateX(0);
	}
}
